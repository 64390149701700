import { defineStore } from 'pinia'
/**
 * 使用方法
 *  import { useStore } from "@/store/store";
    const store = useStore();
 * 
 */
export const useStore = defineStore('text', {
  state: () => {
    return {
      queryLength: '0', //blast结果页面中的字体长度，父子关系嵌套的太深了
      taskId: '',
      blastType: '', //P 还是N
      dataTepe: '', //数据库类型
      submittime: '', //提交时间
      BLastVisualData: <any>{}, //Blast可视化存放数据为了达到数据缓存
      userinfo: <any>{}, //微信扫码返回的头像用户名称
      HomeKeyWord: <string | number>'',
      NewsType: <any>'',
      anchorSkipid: <string>'',
    }
  },
  getters: {},
  actions: {},
  persist: {
    enabled: true, //开启持久化
    strategies: [{ storage: localStorage, paths: ['counter'], key: '' }], //storage：存储的方式,key:密钥默认为pinia的ID
  },
})
